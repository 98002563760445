.tab{
    /* border: 1px solid var(--primayColor); */
    background-color: #fff;
    border-radius: 100%;
    padding: 15px 10px;
    cursor: pointer;
    /* box-shadow: 15px 3px 40px -10px rgba(0,0,0,0.75); */
    box-shadow: 15px 4px 40px -14px rgba(0,0,0,1);
}
.active-tab{
    background-color: var(--primayColor);
    box-shadow: 15px 3px 40px -14px rgba(0,0,0,0.1);
    color: white;
    width:56px;
}
.active-tab img {
    fill: #fff !important;
}

.travel-header {
    color: var(--primayColor);
    font-family: Helvetica, sans-serif;
    font-size: 26px;
}

.travel-header-bold {
    color: var(--primayColor);
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    font-size: 26px;
}

.w-40{
    width:40%;
}
.w-60{
    width:60%;
}
.w-35{
    width:35%;
}
.w-64{
    width:64%;
}
.w-90{
    width:90%;
}
.w-24{
    width:24.5%;
}
.w-22{
    width:22.5%;
}
.w-48{
    width:48%;
}
.w-70{
    width: 69%;
}
.w-40{
    width: 40%;
}

.margin-right{
    margin-right: 5px;
}
.mainBanner{
    height: 100vh;
    background-size: 'cover';
    border-radius: '0px 0px 0px 130px';
    width: 100%;
    position: sticky;
    top:0%;
    overflow: hidden;
}
.overlay{
    height: 100vh;
    width: 100%;
    background-color: rgba(119,18,106,0.5);
    padding-top: 50%;
    padding-left: 10%;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}
.overlay span{
    font-size: 40px;
    font-weight: 100;
}
.overlay h2{
    font-size: 50px;
}
.mainRight{
    position: absolute;
    right: 0%;
    width: 57%;
    z-index: -1;
}
.mainLeft{
    z-index: 3;
}
.bg-grey{
    /* background-color: #F1F1F1; */
    background-color: white;

}
.mainFlightresult {
    height: 100%;
    position: fixed;
    width: -webkit-fill-available;
    overflow-y: auto;
}
.colorPrimary{
    color: var(--primayColor);
}
.filterFlight{
    width: 600px;
}
.filterMainrange{
    border: 1px solid var(--primayColor) !important;
}
.bookFlight{
    width: auto;
}
.backcolorPrimary{
    background-color: var(--primayColor) !important;
}
.fsc-5{
    font-size: 14px;
    color: #7A7A7A;
    font-family: Arial, Helvetica, sans-serif;
}
.passengerDetailDrwaer{
    width: 800px;
}
.bg-dark-grey{
    background-color: #CBCDCE;
}
.passengertable td{
    border-right: 1px solid white;
}
::-webkit-scrollbar {display:none;}

a{
    color: unset !important;
}
.fw-bold-c{
    font-weight: 600 !important;
}
.filterCar{
    width: 400px;
}
a{
    text-decoration: none !important;
}
input{
    outline: var(--primayColor) !important;
    border-color: var(--primayColor) !important;
}
.text-right-c{
    text-align: right ;
}
.dotted-line{
    position: relative;
    width: 25%;
    height: 50%;
    border-bottom: 1px solid black;
    top:-23px;
}

.dotted-line::after{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 90%;
    background-color: white;
    border: 1px solid black;
}
.dotted-line::before{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 90%;
    right: 0%;
    background-color: white;
    border: 1px solid black;
}
.cursor-pointer{
    cursor: pointer;
}
@media only screen and (max-width: 900px) {
.passengerDetailDrwaer {
    width:90%;
}
.passengertable{
    width:90%;
}
.passengerDetailDrwaer.m-5{
    margin: 1rem!important;
}

}
@media only screen and (max-width: 700px) {
.filterFlight {
    width: 90%;
}
}
@media only screen and (max-width: 600px) {
    .travel-header {
        font-size: 20px;
    }
    
    .travel-header-bold {
        font-size: 20px;
    }
     .passengerDetailDrwaer{
        width:420px;
    }
    .passengertable{
        width:420px;
    }
}
@media only screen and (max-width: 449px) {
    .passengerDetailDrwaer{
        width:320px;
    }
    .filterFlight{
        width:320px;
    }
}