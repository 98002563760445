.MuiSelect-select {
  height: 10px !important;
  min-height: 10px !important;
  display: flex !important;
  align-items: center !important;
  background-color: rgb(255, 255, 255) !important;
}

.datePicker-container {
  height: 45px;
  
  background-color: rgb(255, 255, 255) !important;
}

.MuiButton-root {
  height: 43px;
}

.data input {
  height: 10px;
}

.MuiOutlinedInput-input {
  height: 10px !important;
}

@media only screen and (max-width: 600px) {

  .marginLeft {
    margin-left: 0 !important;
  }
}