.search-travel-btn {
  background-color: var(--primayColor);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid var(--primayColor);
  border-radius: 6px;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
}

.search-travel-btn:hover {
  background-color: #fff;
  color: var(--primayColor);
}

.search-travel-btn-disabled{
    background-color: #D3D3D3;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #D3D3D3;
    border-radius: 6px;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    color: dimgray;
}

.hotel-result {
  background-color: #f1f1f1;
  height: 100vh;
  width: -webkit-fill-available !important;
}

.hotel-card-title {
  color: #282828;
  font-size: 18px;
  font-weight: 500;
}

.hotel-card-text {
  color: #565656;
  font-size: 16px;
}

.hotel-card-price {
  font-weight: 500;
  color: #565656;
}

.hotel-filter {
  width: 350px;
}

.hotel-filter-title {
  font-size: 20px;
  color: #282828;
  font-weight: 500;
}

.hotel-filter-line {
  border-bottom: 2px solid #d4d4d4;
}

.hotel-details {
  background-color: #f1f1f1;
  height: 100%;
}

.subBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 40px 20px 40px;
}

.hiddenDiv {
  margin-top: 20px;
  width: 200px;
  height: 400px;
  overflow-y: scroll;
}

.logo {
  object-fit: cover;
  width: 100%;
  height: 400px;
  border-radius: 5px;
}

.sublogo {
  object-fit: cover;
  width: 100%;
  height: 70px;
  border-radius: 5px;
  cursor: pointer;
}

.arrowBack {
  border: 2px solid var(--primayColor);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.back {
  font-size: 18px;
  color: #0a0a0a;
  font-weight: 500;
  cursor: pointer;
  width: 90px;
}

.hotel-details-line {
  border-bottom: 1px solid #737373;
}

.hotel-details-title {
  color: var(--primayColor);
  font-size: 25px;
}

.hotel-details-text {
  font-weight: 500;
}

.hotel-details-text2 {
  font-weight: 400;
}

.hotel-details-price {
  font-size: 24px;
  color: var(--primayColor);
  font-weight: bold;
}

.hotel-details-text3 {
  font-size: 20px;
  color: var(--primayColor);
  font-weight: 500;
}

.hotel-details-text4 {
  color: #828282;
}
.hotel-details-text5 {
  font-size: 13px;
  color: #828282;
}

.hotel-details-offer-line {
  border-bottom: 2px solid #e3e3e3;
}

.hotel-details-offer {
  background-color: #fff;
  height: 400px;
  border-radius: 5px;
  overflow-y: scroll;
}

.hotelcard {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  transition: all 0.5s;
}
.mainTravelcard1 {
  width: 100%;
  height: 170px;
  border-radius: 8px 8px 0px 0px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.hotelcard:hover {
  transform: scale(1.2);
}
.hotelRating {
  padding: 5px 9px !important;
}
.hotel-details {
  width: -webkit-fill-available !important;
}
.mobileWidth {
  min-width: 60px !important;
}
.align-itemsEnd {
  align-items: flex-end !important;
}

.t-error{
  width: 100px;
}


@media only screen and (max-width: 600px) {
  .sublogo {
    width: auto;
  }
  .hiddenDiv {
    height: auto;
  }
  .subBanner {
    margin: 10px 10px 20px 10px;
  }
  .mobileHeader {
    flex-direction: column !important;
  }
  .marginTop {
    margin-top: 20px;
    align-items: flex-start !important;
  }
  .marginLeft {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 500px) {
  .m-margin {
    margin: 0 auto !important;
  }
  .mobileWidth {
    min-width: 45px !important;
  }
}
@media only screen and (max-width: 449px) {
  .hotel-filter {
    width: 320px;
  }
}
