.profilePic {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin: 0 auto 15px;
    position: relative;
    border: 2px solid #CBCDCE;
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, .15));
}

.profileDelete {
    position: absolute;
    background-color: #f1f1f1;
    display: inline-block;
    width: 25px;
    height: 25px;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    padding: 0 1px;
    cursor: pointer;
    transition: .3s all;
}

.profileDelete:hover {
    background-color: #CBCDCE;
}

.profileDelete svg {
    fill: #000000;
    height: 18px;
}

.profilePic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.profileBtn {
    background-color: #F1F1F1;
    border: 1px solid #CBCDCE;
    border-radius: 4px;
    transition: .35s all;
    padding: 6px 5px 6px 8px;
    display: inline-flex;
    width: 145px;
    align-items: center;
    justify-content: center;
    color: #565656;
}

.profileBtn svg {
    fill: #CBCDCE;
    height: 20px;
    margin-left: 4px;
}

.profileChangeBtn {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 34px;
    display: inline-block;
    cursor: pointer;
    z-index: 1;
}

.profileChangeBtn:hover~span {
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, .15));
}

@media only screen and (max-width: 1024px) {
    .profileMobileStack {
        flex-direction: column !important;
    }

    .profileMobileBox {
        padding: 0px 0px 50px 0px !important;
        width: 100% !important;
    }

    .headingMobile {
        display: none;
    }

    .mobileProfile {
        padding: 110px 0px 50px 0px !important;
        background-color: #f1f1f1 !important;
        border-radius: 0px 0px 40px 40px !important;
    }

    .mobileEdit {
        padding: 40px 30px 50px 30px;
        margin-left: 0px !important;
    }

    .profileMobile {
        padding: 0px 0px 0px 0px !important;
    }

}

@media only screen and (max-width: 600px) {
    .profileMobileStack {
        flex-direction: column !important;
    }

    .profileMobileBox {
        padding: 40px 0px 50px 0px !important;
        width: 100% !important;
    }

    .headingMobile {
        display: none;
    }

    .mobileProfile {
        padding: 40px 0px 50px 0px !important;
        background-color: #f1f1f1 !important;
        border-radius: 0px 0px 40px 40px !important;
    }

    .mobileEdit {
        padding: 40px 30px 50px 30px;
        margin-left: 0px !important;
    }

    .profileMobile {
        padding: 0px 0px 0px 0px !important;
    }
}