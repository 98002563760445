.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color:rgb(78 73 77 / 88%);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .modalContainer {
    width: 500px;
    height: 200px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  
  
  .titleCloseBtn {
    display: flex;
    justify-content: space-between;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    /* font-size: 25px; */
    cursor: pointer;
  }
  .but{
    background-color: #77126A;
  }
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 5rem;
    height: 45px;
    margin: 10px;
    border: none;
    color: white;
    border-radius: 8px;
    /* font-size: 20px; */
     cursor: pointer;
  }