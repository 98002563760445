.tabHover:hover{
    background-color: var(--primayColor);
    box-shadow: 15px 3px 40px -14px rgb(0 0 0 / 10%);
    color: white !important;
}

.tabHover:hover img{
   filter: invert(1);
}

.active-tab:hover img {
    filter: none!important;
}
/* .tabHover.active-tab > img:hover {
    filter: none;
} */
.iconspace{
    margin-right: 40px !important;
}
.marginTop-2 {
    margin-top: 2rem!important;
}