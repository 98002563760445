.MuiDrawer-paper{
    border-radius: 40px 0px 0px 40px;
      box-shadow: 0px 0px 25px #000000;
  }
  .MuiTabs-scroller{
    padding:0px !important;
  }
  .Mui-selected{
    background: var(--primayColor) 0% 0% no-repeat padding-box;
      border: 1px solid var(--primayColor);
      color:'#ffffff';
      font-weight:'bold'
  }
  .Mui-selected{
    color:'#ffffff !important';
  }
  /* .css-1vw5i9y-MuiButtonBase-root-MuiTab-root{
    background: #F1F1F1 0% 0% no-repeat padding-box;
      color: var(--primayColor);
      font-weight: 600;
  } */
  .MuiFormControlLabel-label {
    font-family: 'helvetica_neue45_light';
    font-size: 14px;
  }
  .MuiAppBar-root{
    color: #282828;
    font-weight: 600;
    opacity: 1;
    font-family: 'helvetica_neue65_medium';
    background-color: #f1f1f1;
  
  }
  .MuiTab-root[aria-selected="false"]{
   color:var(--primayColor);
  }
  
  .MuiTab-root[aria-selected="true"]{
    background: var(--primayColor) 0% 0% no-repeat padding-box !important;
   }
   .MuiTab-root[aria-selected="true"]{
    background: var(--primayColor) 0% 0% no-repeat padding-box !important;
   }
   .MuiTab-root[aria-selected="false"]{
    color:#282828;
   }
  .MuiAppBar-root{
    box-shadow: none !important;
  }
  .mui-avtar {
    filter: invert(1);
    margin-right:0px !important;
    height: 100% !important;
}
.mui-avtar > img {
  width:auto;
  height:20px;
}
.Mui-selected > .mui-avtar {
  filter: none;

}