.css-heg063 {
    justify-content: center !important;
    align-items: center !important;
}

.css-1ujnqem {
    overflow: initial !important;

}

.css-1pqm26d {
    width: auto !important;
    height: auto !important;
}

.css-1tnnsql>.MuiTab-iconWrapper {
    min-width: 55px !important;
    border: 1px solid #f1f1f1;
    cursor: pointer;
    height: 55px;
    display: flex;
    box-shadow: 15px 4px 15px -15px #000;
    transition: 0.3s all;
    align-items: center !important;
    border-radius: 100% !important;
    justify-content: center !important;
    background-color: #ffffff;
    margin-right: 20px !important;
    margin: 0 auto !important;
    margin-bottom: 10px !important;

}

.css-1tnnsql>.MuiTab-iconWrapper:hover {
    background-color: var(--primayColor) !important;
    border: none !important;
}

.css-1tnnsql.Mui-selected>.MuiAvatar-root {
    background-color: var(--primayColor) !important;
    border: none !important;
}

.css-1anid1y {
    overflowX: scroll !important;
}

.css-1tnnsql.Mui-selected {
    color: #212121 !important;
    background-color: #ffffff !important;
}

.css-1tnnsql {
    font-size: 12px !important;
    text-transform: capitalize !important;
    font-family: 'helvetica_neue45_light !important';
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-width: 84px !important;
}

.css-ttwr4n {
    background-color: #ffffff !important;
}

.css-1aquho2-MuiTabs-indicator {
    background-color: #ffffff !important;
}

.css-1hy9t21 {
    width: auto !important;
    height: auto !important;
}

.Mui-selected.css-1tnnsql>div>img {
    filter: invert(1) !important;
}

/* .Mui-selected.css-1tnnsql > div > img:hover {
    filter: invert(1) !important;
} */


.Mui-selected.css-aym9vq-MuiButtonBase-root-MuiTab-root>div>img {
    filter: invert(1);
}

img.MuiAvatar-img.css-1hy9t21:hover {
    filter: invert(1);
}

.data input {
    text-align: center !important;
}

.pointTab {
    background-color: #77126a;
    padding: 10px;
    color: #f1f1f1;
    border-radius: 8px;
}