.rc-pagination-prev {
  background: #77126A !important;
  /* padding: 0 15px; */
  color: #ffffff;
  border-radius: 3px;
  margin-left: 10px;

}

.select-limit {
  height: 25px !important;
  width: 60px;
  border: 1px solid#77126A;
  color: #77126A;
  text-align: center;
  border-radius: 0;
}

.select-limit:active {
  border: 1px solid#77126A;

}

.select-limit option {
  border-radius: 0;
}

.rc-pagination-next {
  background: #77126A;
  /* padding: 0 15px; */
  color: #ffffff;
  border-radius: 3px;
}


.Pagination-container {
  justify-content: space-between;
}

.rc-pagination-next button {
  background: #77126A !important;
  border: none;
  cursor: pointer;
  color: #ffffff;
}

.rc-pagination-prev button {
  background: #77126A !important;
  border: none;
  cursor: pointer;
  color: #ffffff;
}

.rc-pagination-item-link {
  color: #ffffff !important;
}

.rc-pagination-jump-next {
  color: #000 !important;
}

.rc-pagination-jump-next button:after {
  color: #000 !important;
}

.rc-pagination-jump-prev {
  color: #000 !important;
}

.rc-pagination-jump-prev button:after {
  color: #000 !important;
}