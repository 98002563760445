.mainTravelcard{
    width: 31%;
    height: 170px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.travelCard{
    width: 100%;
    height: 100%;
    background-size: cover !important;
    transition: all 0.85s;

}
.travelCard:hover{
    transform: scale(1.075);
}
.travelCardGrey{
    background-color: #D9D9D9 !important;
}
.travelCardGrey:hover{
    transform: scale(1);
}
.textcard{
    width: 100%;
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    bottom: 0%;
    color: white;
    padding: 3px 0;
    padding-left: 10px;
}
.textcardGrey{
    background-color: #F1F1F1;
    color: black;
}
@media only screen and (max-width: 600px) {
    .mainTravelcard{
        width:49%;
    }
  }