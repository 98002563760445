.sibeBar-nav {
  background: var(--primayColor) 0% 0% no-repeat padding-box;
  background: var(--primayColor) 0% 0% no-repeat padding-box;
  border-radius: 0px 40px 40px 0px;
  opacity: 1;
  z-index: 2;
  padding: 15px 0;
  overflow: auto;
}

.sideBar-logo-container {
  color: white;
  position: fixed;
  top: 0;
  left: 0px;
  width: 132px;
  height: 55px;
  background: var(--primayColor);
  z-index: 1;
  padding-left: 10px;
  border-top-right-radius: 50px;
}

.nav-label-txt {
  margin: 10px 0;
  display: inline-block;
  width: 100%;
}

.nav-label-txt p {
  color: var(--secondaryTextColor) !important;
}

.sibeBar-nav button {
  color: var(--secondaryTextColor);
  margin-top: 1.5rem;
  font-size: 12px;
  font-family: "helvetica_neue45_light";
}

.main-logo {
  width: 110px;
  margin: 1rem auto;
}

.css-aym9vq-MuiButtonBase-root-MuiTab-root>.MuiTab-iconWrapper {
  margin-bottom: 10px !important;
  height: auto;
  width: auto;
  border-radius: 0;
}

.sibeBar-nav {
  width: 150px;
  height: 100vh;
  position: sticky;
  top: 0%;
  margin-left: 0px !important;
}

[role="tabpanel"] {
  padding-left: 160px;
}

.MuiTabs-scroller {
  padding: 3rem 0;
}

.MuiButtonBase-root~.MuiTabs-scroller {
  padding: 0;
}

.css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: var(--primayColor);
  color: var(--secondaryTextColor) !important;
}

.css-10d9dml-MuiTabs-indicator {
  width: 0 !important;
  background-color: transparent !important;
}

.css-1ehaqqx-MuiButtonBase-root-MuiTabScrollButton-root svg {
  fill: #ffffff;
}

.flex-column {
  flex-direction: column;
}

.nav-label-txt span,
.nav-label-txt {
  color: var(--secondaryTextColor) !important;
  text-align: center;
  text-transform: uppercase;
  font-family: "helvetica_neue45_light";
  font-size: 12px;
  margin-top: 0;
  text-decoration: none;
}

.nav-label-txt p {
  margin: 2px 0 0;
}

.sibeBar-nav .MuiAvatar-root {
  width: auto !important;
}

.ImgBox img {
  width: auto !important;
  height: auto !important;
  text-align: center !important;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
  margin: 0 auto !important;
}

.active {
  background-color: rgba(0, 0, 0, 0.2);
}