.textAreaBorder{
    border: 1px solid darkgray;
    border-radius: 3px;
}

.settingLeft{
    border: 1px solid darkgray;
    padding: 20px !important;
    border-radius: 10px;
    border-color: #959595;
}

.settingRight{
    border: 1px solid darkgray;
    padding: 20px !important;
    border-radius: 10px;
    border-color: #959595;
}

.settingsButtons{
    width: 100% !important;
    margin-bottom: 10px !important;
    text-transform: none !important;
    background-color: #FFF !important;
    border: 1px solid var(--primayColor) !important;
    color: var(--primayColor) !important;
}

.settingsButtonActive{
    width: 100% !important;
    margin-bottom: 10px !important;
    text-transform: none !important;
    background-color: var(--primayColor) !important;
    border: none !important;    
    color: var(--secondaryTextColor) !important;
}

.borderButton{
    font-family: helvetica_neue65_medium !important;
    font-size: 16px !important;
    color: var(--primayColor)  !important;
    font-weight: normal !important;
    border: 1px solid var(--primayColor) !important;
    transition: .35s all;
    text-transform: capitalize !important;
}
.borderBtn:hover{
    background-color: var(--primayColor) !important;
    color:var(--secondaryTextColor) !important;
}

.borderBtn:active{
    background-color: var(--primayColor) !important;
    color:var(--secondaryTextColor) !important;
}

.fillButton{
    font-family: helvetica_neue65_medium !important;
    font-size: 16px !important;
    color: var(--secondaryTextColor)  !important;
    background-color: var(--primayColor) !important;
    font-weight: normal !important;
    border: none;
    transition: .35s all;
    text-transform: capitalize !important;
}

.cursor{
    cursor: pointer;
}

@media only screen and (max-width: 1024px){
    .settingMobileContainer{
        flex-direction: column !important;
    }
    .settingMobileBox{
        padding: 0px 0px 50px 0px !important;
            width: 100% !important;
    }
    .headingMobile{
        display: none;
    }
    .contactUsEdit{
        padding: 40px 30px 50px 30px;
        margin-left: 0px !important;
    }
    .profileMobile {
        padding: 40px 0px 0px 0px !important;
    }
}

@media only screen and (max-width: 600px){
    .settingMobileContainer{
        flex-direction: column !important;
    }
    .settingMobileBox{
        padding: 40px 0px 50px 0px !important;
        width: 100% !important;
    }
    .headingMobile{
        display: none;
    }
    .contactUsEdit{
        padding: 40px 30px 50px 30px;
        margin-left: 0px !important;
    }
    .profileMobile {
        padding: 0px 0px 0px 0px !important;
    }
}