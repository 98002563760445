.btn {
  color: var(--primayColor);
  float: right;
}

.center {
  display: flex;
  justify-content: center;
}
.bookFlight {
  margin: 1.5rem 3rem !important;
}
.mobileBlock {
  display: flex !important;
}

.select-dropdown {
  padding: 10.5px 14px !important;
}
.demo-select-small {
  padding: 10.5px 14px !important;
}
.width-75 {
  width: 75% !important;
}
.width-50 {
  width: 50% !important;
}
.width-35 {
  width: 35% !important;
}
.width-64 {
  width: 64% !important;
}
.width-22 {
  width: 22.5% !important;
}
.display-flex {
  display: flex !important;
}
.m-flex {
  display: none !important;
}

.text-error {
  color: rgb(255, 45, 45) !important;
}

/* React Date picker design start */
.react-datepicker {
  /* font-family: "Inter", sans-serif !important; */
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #e4e4e4 !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__navigation--next {
  top: 15px !important;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 15px !important;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__day-name {
  color: #9a9fa5 !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff !important;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__month-container {
  float: left;
  padding: 10px;
}

/* custom style for react datepicker */
.react-datepicker__header {
  text-align: center;
  background-color: #fff !important;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.datepicker-input-style-without-border {
  display: flex !important;
  width: 7.5rem !important;
  max-width: 100% !important;
  border: 0px solid #fff !important;
  border-radius: 0.7rem !important;
  padding: 0.375rem !important;
  background-color: transparent !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50px 50px 50px 50px !important;
  background-color: var(--primayColor) !important;
  color: var(--secondaryTextColor);
}

.datePicker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 7px 7px;
}

.datePicker-container:hover {
  border: 1px solid black;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 1.3rem !important;
  background-color: var(--primayColor) !important;
  color: #fff !important;
}

.mobileWidthFlight{
  min-width: 50px !important;
}

.mobileWidthFlightDisabled{
  background: lightgrey !important;
}
.mobileFlightAdultInput{
  width: 60px !important;
}


@media only screen and (max-width: 1024px) {
  /* Sidebar Section */
  .stackMobile {
    flex-direction: column !important;
  }
  .bookFlight {
    width: auto !important;
    padding: 0px 15px !important;
  }
  .columnReverse {
    flex-direction: column-reverse !important;
  }
}
@media only screen and (max-width: 749px) {
  .mobileBlock {
    display: block !important;
    width: 100% !important;
  }
  .mobileStack500 {
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 600px) {
  .m-flex {
    display: flex !important;
  }
  .backButton {
    font-size: 18px;
    color: #0a0a0a;
    font-weight: 500;
    cursor: pointer;
    width: 90px;
    position: relative;
    left: 20px;
    top: 20px;
  }
  .twoYearsContainer{
    margin-left: 0 !important;
  }
  .childContainer{
    margin-left: 0 !important;
  }
  .mobileFlightAdultInput{
    width: 100% !important;
  }
  .mobileWidthFlight{
    width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  .mobileBlock {
    display: block !important;
  }
  .m-width-180 {
    width: 180px !important;
  }
  .mobileStack500 {
    flex-direction: column !important;
  }
  .width-70 {
    width: 70% !important;
  }
  .width114 {
    width: 114px !important;
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 449px) {
  .bookFlight {
    width: 320px !important;
    padding: 0px 0px !important;
    margin: 3rem 1rem !important;
  }
  .filterCar {
    width: 320px !important;
  }
  .width300 {
    width: 300px !important;
  }
}
.Sorted_By{
  
  font-size: 12px;
  display: flex;
  
  cursor: pointer;
  overflow-x: hidden;
  padding: 10px;
}
.item{
   padding: 0px;
   margin-left: 60px;
    display: flex;
}
.main{
  width: 100%;
  background-color: #f2f5f8;
  display: flex;
  
}
.heading{
  padding: 10px;
  font-size: 12px;
  margin-left: 30px;
}
@media only screen and (max-width: 768px) {
  .Sorted_By {
    overflow-x: auto;
  }
}
