/*! font-family: 'helveticaneueregular';  */

@font-face {
  font-family: 'helveticaneueregular';
  src: url('../src/assets/fonts/helveticaneue-regular.woff2') format('woff2'),
    url('../src/assets/fonts/helveticaneue-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/*! font-family: 'helvetica_neuebold';  */

@font-face {
  font-family: 'helvetica_neuebold';
  src: url('../src/assets/fonts/helvetica_neue_bold-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/helvetica_neue_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/*!  font-family: 'helvetica_neue45_light'*/

@font-face {
  font-family: 'helvetica_neue45_light';
  src: url('../src/assets/fonts/helveticaneue-light_2-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/helveticaneue-light_2-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/*!  font-family: 'helvetica_neue65_medium' */

@font-face {
  font-family: 'helvetica_neue65_medium';
  src: url('../src/assets/fonts/helveticaneue-medium_6-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/helveticaneue-medium_6-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/*! font-family: 'helveticaneuebold_'; */

@font-face {
  font-family: 'helveticaneuebold_';
  src: url('../src/assets/fonts/helvnb_4-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/helvnb_4-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

:root {
  --backcolor: #77126A;
  --primayColor: #77126A;
  --primaryTextColor: #77126A;
  --secondaryTextColor: #FFFFFF;
  --primaryBorderColor: #77126A;
}

html,
body {
  font-family: 'helvetica_neue45_light';
  background-color: #f2f5f8 !important;
}

/* .bgPrimaryColor{
  background-color: var(--backcolor);
}

.primaryTextColor{
  color: var(--primaryTextColor);
}

.primaryBtnColor{
  color: var(--primaryTextColor);
} */

.bg-light-blue {
  background-color: #f2f5f8 !important
}

h1 {
  font-family: Helvetica, sans-serif;
  letter-spacing: -0.86px;
  color: var(--primaryTextColor) !important;
  font-weight: 300;
  font-size: 26px !important;
  margin: 0 auto;
  margin: 1rem auto;

}

.commonw50 {
  width: 50%;
}

.mobileStack>.commonw50:first-child {
  width: calc(50% - 75px);
}

.mobileStack>.commonw50:last-child {
  width: calc(50% + 75px);
}

.borderBtn {
  font-family: helvetica_neue65_medium !important;
  font-size: 14px !important;
  color: #4b7712 !important;
  font-weight: normal;
  border: 1px solid var(--primayColor) !important;
  transition: .35s all !important;
  background-color: #fff !important;

}

.borderBtn:hover {
  background-color: var(--primayColor) !important;
  color: #ffffff !important;
}

.fillBtn {
  font-family: helvetica_neue65_medium !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: normal;
  border: 1px solid var(--primayColor) !important;
  transition: .35s all !important;
  background-color: var(--primayColor) !important;
}

.fillBtn:hover {
  background-color: #fff !important;
  color: var(--primayColor) !important;
}

.fillBtn-loader {
  font-family: helvetica_neue65_medium !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: normal;
  border: 1px solid var(--primayColor) !important;
  transition: .35s all !important;
  background-color: var(--primayColor) !important;
}

.fillBtn-loader-true {
  font-family: helvetica_neue65_medium !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: normal;
  border: 1px solid var(--primayColor) !important;
  transition: .35s all !important;
  background-color: #fff !important;
}

.fillBtn-loader:hover {
  background-color: #fff !important;
  color: var(--primayColor) !important;
}

.cancelLink {
  color: '#212121 !important',
}

.knowmore-btn {
  background: transparent linear-gradient(108deg, #FFFFFF67 0%, #FFFFFF4D 100%) 0% 0% no-repeat padding-box !important;
  border: 1px solid #FFFFFF58 !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  font-family: helvetica_neue65_medium !important;
  font-size: 12px !important;
  color: #fff !important;
  text-transform: initial !important;
  padding: 6px 18px !important;
}

.knowmore-btn:hover {
  background: var(--unnamed-color-591b70) 0% 0% no-repeat padding-box !important;
  background: #591B70 0% 0% no-repeat padding-box !important;
  border: 1px solid #FFFFFF58 !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}

.css-k4qjio-MuiFormHelperText-root {
  color: #565656
}

.mui-useravtar {
  background-color: var(--primayColor) !important;
  width: 50px !important;
  height: 50px !important;
}

.mui-useravtar>img {
  width: auto !important;
  height: auto !important;
}

.profilePic-login {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.profilePic-login img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.paymentUser>img {
  height: 100% !important;
}

.typotext {
  font-family: helvetica_neue45_light !important;
  color: #000000;
}

.tabScroll .MuiTabs-scroller {
  overflow-x: scroll !important;
}

.mobilemt-5 {
  margin-top: 1.5rem !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.strip-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
}

.otp-input-style {
  width: 2.5rem !important;
  text-align: center;
  height: 3rem !important;
  border-radius: 0.7rem;
  border: 2px solid var(--primayColor) !important;
}

/* #dateDeparture, #dateArrival, #textAdult, #textChild, #textInfant, #Check-In, #dateCheck-Out, #demo-simple-select{
  height: 40px;
  padding: 0px 14px;
} */
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background-color: #FFFFFF !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #FFFFFF !important;
}

.makeStyles-voucherValue-41 {
  background-color: #f2f5f8 !important;
}

.cards {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  max-width: 820px;
}

.card--1 .card__img,
.card--1 .card__img--hover {
  /* background-image: url(''); */
  width: 100%;
  height: 150px;

}

.card--2 .card__img,
.card--2 .card__img--hover {
  /* background-image: url(''); */
  width: 100%;
  height: 150px;
}

.card__img {
  visibility: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 170px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

}

.card__info-hover {
  position: absolute;
  padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.card__img--hover {
  transition: 0.2s all ease-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  height: 170px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  top: 0;

}

.card {
  cursor: pointer;
  margin-right: 25px;
  margin-bottom: 20px;
  transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #FFF;
  width: 100%;
  /* height: 240px; */
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
}

.card:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
  transform: scale(1.10, 1.10);
}

.card__info {
  z-index: 2;
  background-color: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 16px 12px 12px 12px;
  height: 110px;
}

.card__category {
  font-family: 'helvetica_neue65_medium';
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 500;
  color: #868686;
}

.card__title {
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: 'helvetica_neue65_medium';
  color: #565656;
  font-size: 16px;
  height: 45px;
}

.card__by {
  font-size: 12px;
  font-family: 'helvetica_neue65_medium';
  font-weight: 500;
}

.card:hover .card__img--hover {
  height: 100%;
  opacity: 0.3;
}

.card:hover .card__info {
  background-color: transparent;
  position: relative;
}

.card:hover .card__info-hover {
  opacity: 1;
}

/* .card:hover .card__title {
  color: #000;
}

.card:hover .card__by {
  color: #000;
} */



.shop-card--1 .shop-card__img,
.shop-card--1 .shop-card__img--hover {
  /* background-image: url(''); */
  width: 100%;
  height: 180px;

}

.shop-card--2 .shop-card__img,
.shop-card--2 .shop-card__img--hover {
  /* background-image: url(''); */
  width: 100%;
  height: 250px;
}

.shop-card__img {
  visibility: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

}

.shop-card__info-hover {
  position: absolute;
  padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.shop-card__img--hover {
  transition: 0.2s all ease-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  height: 180px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  top: 0;

}

.shop-card {
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;
  transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #FFF;
  width: 95%;
  /* height: 320px; */
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
}

.shop-card:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
  transform: scale(1.10, 1.10);
}

.shop-card__info {
  z-index: 2;
  background-color: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 16px 12px 12px 12px;
  height: 110px;
}

.shop-card__category {
  font-family: 'helvetica_neue65_medium';
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 500;
  color: #868686;
}

.shop-card__title {
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: 'helvetica_neue65_medium';
  color: #565656;
  font-size: 16px;
  height: 45px;
}

.shop-card__by {
  font-size: 12px;
  font-family: 'helvetica_neue65_medium';
  font-weight: 500;
}

.shop-card:hover .shop-card__img--hover {
  height: 100%;
  opacity: 0.3;
}

.shop-card:hover .shop-card__info {
  background-color: transparent;
  position: relative;
}

.shop-card:hover .shop-card__info-hover {
  opacity: 1;
}

@media only screen and (max-width: 1024px) {

  /* Sidebar Section */
  .mobileSideHide {
    height: 100% !important;
    position: fixed !important;
    z-index: 4 !important;
    left: -150px !important;
    top: 16px !important;
    transition: 1s;
  }

  .mobileSideShow {
    height: 100% !important;
    position: fixed !important;
    z-index: 4 !important;
    left: -16px !important;
    top: 16px !important;
    transition: 1s;
  }

  /* Mobile Appbar */

  .mobileStack {
    flex-direction: column-reverse !important;
  }

  .mobileBox {
    width: 100% !important;
    z-index: 2;
  }

  .mobileBox .mainBanner {
    border-radius: 0px 0px 40px 40px !important;
    height: 380px;
  }

  .mobileBox .position-relative {
    display: none;
  }

  .mobileBox .mt-5 {
    margin-top: 10px !important;
  }

  .mobileBox>.mt-5 {
    font-size: 20px !important;
  }

  .mobileAppBar {
    top: 0px;
    position: fixed;
    z-index: 5;
    width: 100%;
    display: block !important;
  }

  .mobileAppBar>.MuiPaper-root {
    background-color: var(--primayColor);
  }

  .mobileBox>.cardDatail {
    margin-top: 0px;
    margin-top: 0px;
    position: relative;
    border-radius: 0px;
  }

  .mobileBox>.jss10 {
    margin-top: 0px !important;
    margin-top: 0px;
    position: relative;
    border-radius: 0px;
  }

  .mobileDetailAppBar {
    top: 55px;
    position: fixed;
    z-index: 5;
    width: 100%;
    display: block !important;
  }

  .mobileDetailAppBar>.MuiPaper-root {
    color: #000000;
    border-radius: 0px 0px 20px 20px;
    background-color: #ffffff;
  }

  .mobileIndex {
    display: none;
  }

  .mobilemt-5 {
    margin-top: 8.5rem !important;
  }

  .mobilemt-3 {
    margin-top: 3.5rem !important;
  }


  .mobilemt-1 {
    margin-top: 1.5rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .mobileStack {
    flex-direction: column-reverse !important;
  }

  .mobileBox {
    width: 100% !important;
  }

  .mobileBox .mainBanner {
    border-radius: 0px 0px 40px 40px !important;
    height: 300px;
  }

  .mobileBox .position-relative {
    display: none;
  }

  .mobileBox .mt-5 {
    margin-top: 10px !important;
  }

  .mobileBox>.mt-5 {
    font-size: 20px !important;
  }

  .mobileBox>.cardDatail {
    margin-top: 0px;
    position: relative;
    border-radius: 0px;
  }

  /* Sidebar Section */
  .mobileSideHide {
    height: 100% !important;
    position: fixed !important;
    z-index: 4 !important;
    left: -150px !important;
    top: 16px !important;
    transition: 1s;
  }

  .mobileSideShow {
    height: 100% !important;
    position: fixed !important;
    z-index: 4 !important;
    left: -16px !important;
    top: 16px !important;
    transition: 1s;
  }

  /* Mobile Appbar */

  .mobileAppBar {
    top: 0px;
    position: fixed;
    z-index: 5;
    width: 100%;
  }

  .mobileDetailAppBar {
    top: 55px;
    position: fixed;
    z-index: 5;
    width: 100%;
  }

  .mobileAppBar>.MuiPaper-root {
    background-color: var(--primayColor);
  }

  .mobileDetailAppBar>.MuiPaper-root {
    color: #000000;
    border-radius: 0px 0px 20px 20px;
    background-color: #ffffff;
  }

  .mobileIndex {
    display: none;
  }

  .mobileFlexDirection {
    flex-direction: column !important;
  }

  .mobilemt-2 {
    margin-top: 1.5rem !important;
  }
}