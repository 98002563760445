.demo{
    margin: 10px 0px;

}
.voucherText{
    color: 'var(--primayColor)';
    margin: '10px 0px';
    font-family: 'helvetica_neue45_light';
    font-size: '30px';
}
.MuiTab-root.descterm[aria-selected="true"] {
    background: white !important;
}