::-webkit-scrollbar {display:none;}
.att-left{
    height: 100vh;
    background-color: #fff;
}
.top-text{
    color: var(--primayColor);
    font-size: 36px;
}
.top-text-bold{
    font-weight: 700;
}
.card-bottom{
    background-color: #f1f1f1;
    height: 50px;
}
.card-img-text{
    font-size: 18px;
    color: #FEFEFE;
    font-weight: 400;
    letter-spacing: 0.18px;
}
.card-text-bottom{
    font-size: 18px;
    color: #565656;
    letter-spacing: 0.18px;
}
.card-heart{
    color: #1B252C;
    width: 25px;
}