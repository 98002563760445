
.loginErrorLable{
    max-width: 390px !important;
    width: 100% !important;
    white-space: normal !important;
}

@media only screen and (max-width: 600px) {
    /* Login Drawer Section */
    .Login-Drawer{
        position: inherit !important;
    }
    .InputBox {
        min-width: -webkit-fill-available !important;
    }
}